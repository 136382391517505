// Libraries
import { useSelector } from 'react-redux';
// Modules
import { useHistory, withRouter } from 'react-router-dom';
import { getLocale, getParams } from 'base/BaseSelectors';

export function getOneIDIframeRendererUrl() {
    const oneIDWrapperOrigin =
        window.location.hostname !== 'localhost' ? window.location.origin : 'https://develop-app.certn.co'; // localhost doesn't work with Veriff URL shortener used in QR code.
    return `${oneIDWrapperOrigin}/one_id`;
}

const VeriffIframeRenderer = () => {
    const history = useHistory();
    const incomingParams = useSelector(getParams);
    const locale = useSelector(getLocale);
    const encodedSessionURL = incomingParams?.session;
    const handleSubmit = () => {
        postMessageToParent('success');
        if (incomingParams?.on_success_url) {
            window.location.href = incomingParams.on_success_url;
        } else {
            history.push(`/thank-you`);
        }
    };

    // target origin set to any for postmessage as we wont know specific urls
    const postMessageToParent = (msg) => window.parent.postMessage(JSON.stringify({ payload: { value: msg } }), '*');

    // hide Zendesk widgets
    if (window.zE) window.zE('webWidget', 'hide');
    if (window.adaEmbed) window.adaEmbed.stop();

    if (encodedSessionURL) {
        try {
            const decodedSessionURL = atob(encodedSessionURL);
            window.veriffSDK.createVeriffFrame({
                url: decodedSessionURL,
                hostUrl: getOneIDIframeRendererUrl(),
                lang: locale,
                onEvent(msg) {
                    switch (msg) {
                        case 'STARTED':
                            postMessageToParent('started');
                            break;
                        case 'FINISHED':
                            handleSubmit();
                            break;
                        case 'CANCELED':
                            postMessageToParent('canceled');
                            break;
                        default:
                            break;
                    }
                },
            });
        } catch (error) {
            console.error(`Someting went wrong with error: ${error}`);
            postMessageToParent('error');
        }
    } else {
        console.warn('No OneID session found.');
    }
    return <div />;
};
export default withRouter(VeriffIframeRenderer);
