import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import { CombinedCheckboxClickwrap } from 'components/Clickwrap/CombinedCheckboxClickwrap';
import { useEffect } from 'react';

type ServicesContractProps = {
    onValid?: () => void;
    onInvalid?: () => void;
    setSigned?: (signed: boolean) => void;
    signerId: string;
    language?: string;
};

export const ServicesContract: React.FC<ServicesContractProps> = ({
    onValid,
    onInvalid,
    setSigned,
    signerId,
    language,
}) => {
    const SERVICES_CONTRACT_GROUP_KEY =
        language === 'fr'
            ? `${process.env.REACT_APP_CLICKWRAP_SERVICES_CONTRACT_GROUP_KEY}-fr`
            : process.env.REACT_APP_CLICKWRAP_SERVICES_CONTRACT_GROUP_KEY;

    useEffect(() => {
        if (!SERVICES_CONTRACT_GROUP_KEY) {
            ErrorAlertAPI();
            console.error('Unable to initialize signup contract. Missing Clickwrap Services Contract Group Key.');
        }
    }, [SERVICES_CONTRACT_GROUP_KEY]);

    if (!SERVICES_CONTRACT_GROUP_KEY) {
        return null;
    }

    return (
        <CombinedCheckboxClickwrap
            groupKey={SERVICES_CONTRACT_GROUP_KEY}
            onValid={onValid}
            onInvalid={onInvalid}
            setSigned={setSigned}
            signerId={signerId}
        />
    );
};
