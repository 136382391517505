import React, { useState } from 'react';
import { TrackNavigation } from 'views/welcome/components';
import { postRequest } from 'utils/http';
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import Title from 'certn-ui/Title';
import { FormattedMessage, useIntl } from 'react-intl';
import { BiometricConsentWrapper } from './styled';
import { Clickwrap } from 'components/Clickwrap';
import { getLanguage } from '../../../../base/BaseSelectors';
import { useSelector } from 'react-redux';
import { getTeamName } from '../../WelcomeSelectors';

type BiometricConsentClickwrapProps = {
    signerId: string;
    handleNext: () => void;
};

export const BiometricConsentClickwrap: React.FC<BiometricConsentClickwrapProps> = ({ signerId, handleNext }) => {
    const intl = useIntl();
    const [isConsentGiven, setIsConsentGiven] = useState(false);
    const language = useSelector(getLanguage);
    const teamName = useSelector(getTeamName);
    const bioRenderData = { company_name: teamName };
    const acceptanceText = intl.formatMessage({
        id: 'welcome.consentAndDisclosure.checkBoxConsentText',
        defaultMessage: 'I understand and agree to this document',
    });

    const getBiometricConsentContractId = () => {
        switch (language) {
            case 'fr':
                return '340735';
            case 'es':
                return '600519';
            default:
                return '276358';
        }
    };

    const handleValid = async () => {
        const signingData = {
            applicant_id: signerId,
            external_id: getBiometricConsentContractId(),
            render_data: bioRenderData,
        };

        try {
            await postRequest({
                version: 'v2',
                endpoint: `/applicant-consent-and-disclosure-document/create/`,
                body: JSON.stringify(signingData),
            });
            setIsConsentGiven(true);
        } catch (error) {
            ErrorAlertAPI(error);
            setIsConsentGiven(false);
        }
    };

    return (
        <>
            <Title>
                <FormattedMessage id="welcome.BiometricConsent.title" defaultMessage="Biometrics Consent" />
            </Title>
            <FormattedMessage
                id="welcome.BiometricConsent.subTitle"
                defaultMessage="Please help us verify your identity so we can continue with your background check"
            />

            <BiometricConsentWrapper>
                <Clickwrap
                    contractId={getBiometricConsentContractId()}
                    clickWrapStyle="scroll"
                    forceScroll
                    signerId={signerId}
                    renderData={bioRenderData}
                    allowDisagreed={false}
                    onValid={handleValid}
                    acceptanceLanguage={acceptanceText}
                    setSigned={setIsConsentGiven}
                />
                <TrackNavigation disabled={!isConsentGiven} handleSubmit={handleNext} />
            </BiometricConsentWrapper>
        </>
    );
};
