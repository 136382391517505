// Libraries
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Modules
import Image from 'certn-ui/Image';
import Title from 'antd/es/typography/Title';
import plainSuccessImage from 'images/oneID_assets/oneid-plain-success.svg';
import styles from '../../../../../styles/styles';
import { Layout } from 'certnd';
import Text from 'certn-ui/Text';

const OneIDThankYouPage = () => {
    if (window.zE) window.zE('webWidget', 'hide');
    if (window.adaEmbed) window.adaEmbed.stop();
    return (
        <>
            <Layout.Vertical height="80vh" justify="center" align="center">
                <Image src={plainSuccessImage} style={{ marginBottom: '30px' }} />
                <Title>
                    <FormattedMessage id="guest.OneIDAllDone.alldone" defaultMessage="All done!" />
                </Title>
                <Text
                    align="center"
                    style={{ color: styles.color.certnGray600, fontSize: styles.font.med, fontWeight: '100' }}
                >
                    <FormattedMessage
                        id="guest.OneIDAllDone.closeWebsite"
                        defaultMessage="Your verification details have been submitted. You may now close this website."
                    />
                </Text>
            </Layout.Vertical>
        </>
    );
};
export default OneIDThankYouPage;
