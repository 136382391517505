// Libraries
import React from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider as StyledThemeProvider } from 'styled-components/macro';
import queryString from 'query-string';
import Cookies from 'js-cookie';
import { useLocation, Switch, Route, Redirect } from 'react-router-dom';
import Routes from 'Routes';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { useTrackingPageViews } from 'hooks/trackingHooks';

// Components
import { Auth0Provider } from 'components/Auth0Provider';
import { BaseContainer } from 'certn-ui/Layout';
import GlobalStyles from 'styles/GlobalStyles';
import { ErrorAlertNoTranslate } from 'certn-ui/ErrorAlert';
import { ScrollToTop, Logout, CertnUiWrapper } from './components';
import GoogleRecaptchaWrapper from './components/GoogleRecaptchaWrapper';
import { GlobalProvider } from 'components/GlobalProvider';
import RouteErrorBoundary from 'certn-ui/RouteErrorBoundary';
import Manager from 'views/manager/Manager';
import Guest from 'views/guest/Guest';
import Welcome from 'views/welcome/Welcome';
import Reference from 'views/reference/Reference';
import Applicant from 'views/applicant/Applicant';
import ReportVerify from 'views/applicant/views/report-verifiy/ReportVerify';
import AdverseActionVerify from 'views/applicant/views/adverse-action-verify/AdverseActionVerify';

// Modules
import config from 'auth_config.json';
import { Path } from 'modules/';
import GooglePlaces from 'modules/GooglePlaces';
import styles from 'styles/styles';
import { getLanguageByPriority } from 'utils/language';

// Actions & Selectors
import { getSkinColour, getLocale, getLanguage, getUser } from './BaseSelectors';
import { fetchSkin, saveParams, setLocale, setLaunchDarklyFlags } from './BaseActions';

export const defaultTheme = {
    ...styles,
    'primary-color': styles.color.certnGreen700,
    color: { ...styles.color, primary: styles.color.certnGreen700 },
};

const initFavicon = (subdomain) => {
    const favicon = document.getElementById('favicon');
    const appleTouchIcon = document.getElementById('favicon');
    const favicon32 = document.getElementById('favicon-32');
    const favicon16 = document.getElementById('favicon-16');

    switch (subdomain) {
        case 'mycrc':
            favicon.href = 'favicon-mycrc.ico';
            appleTouchIcon.href = 'apple-touch-icon-mycrc.png';
            favicon32.href = 'favicon-mycrc-32x32.png';
            favicon16.href = 'favicon-mycrc-16x16.png';
            break;

        case 'lime':
            favicon.href = 'favicon-lime.ico';
            appleTouchIcon.href = 'apple-touch-icon-lime.png';
            favicon32.href = 'favicon-lime-32x32.png';
            favicon16.href = 'favicon-lime-16x16.png';
            break;

        default:
            favicon.href = 'favicon.ico';
            appleTouchIcon.href = 'apple-touch-icon.png';
            favicon32.href = 'favicon-32x32.png';
            favicon16.href = 'favicon-16x16.png';
            break;
    }
};

const Base = (props) => {
    const { flags } = props;
    const location = useLocation();
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const locale = useSelector(getLocale);
    const language = useSelector(getLanguage);
    const skinColour = useSelector(getSkinColour);
    const [, forceUpdate] = React.useReducer((x) => x + 1, 0);
    const isMounted = React.useRef(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const customTheme = {
        ...styles,
        'primary-color': skinColour,
        color: { ...styles.color, primary: skinColour },
    };

    const activeTheme = skinColour ? customTheme : defaultTheme;

    const preferredLanguage = React.useMemo(() => {
        const { lang: languageFromQueryParam = undefined } =
            (location.search && queryString.parse(location.search)) || {};
        const languageFromStore = locale.split('-')[0];
        return getLanguageByPriority(languageFromQueryParam, languageFromStore);
    }, [locale, location.search]);

    const initLocaleCallback = React.useCallback(() => {
        dispatch(setLocale(preferredLanguage));
    }, [dispatch, preferredLanguage]);

    // monitor and update launch darkly
    React.useEffect(() => {
        // set launch darkly flags, primary use in functions that have access to state but cannot use hooks
        dispatch(setLaunchDarklyFlags(flags));
    }, [flags, dispatch]);

    React.useEffect(() => {
        if (flags?.webFeatureEnableAdaChat) {
            if (window.zE) {
                window.zE('webWidget', 'hide'); // Hide Zendesk widget
            }
        } else if (window.adaEmbed) {
            window.adaEmbed.stop();
        }
    }, [flags]);

    // keep Zendesk in sync when the locale changes
    React.useEffect(() => {
        initLocaleCallback();
        if (window.zE) window.zE('webWidget', 'setLocale', locale);
    }, [initLocaleCallback, locale]);

    const updateComponent = () => {
        // increases the sync priority of forceUpdate for matchMedia events
        ReactDOM.flushSync(() => forceUpdate());
    };

    // onboarding flow has various params we save into cookies
    const saveParamsCallback = React.useCallback(() => {
        if (location.search) {
            const params = queryString.parse(location.search, { expires: 1 });
            if (params && params.magic) Cookies.set('magicToken', params.magic, { expires: 1 });
            if (params && params.type) Cookies.set('accountType', params.type, { expires: 1 });
            if (params && params.session)
                Cookies.set('onboardingId', params.session, { expires: 1, sameSite: 'None', secure: true });
            if (params && params.token)
                Cookies.set('passwordToken', params.token, { expires: 1, sameSite: 'None', secure: true });
            if (params && params.onboardingType)
                Cookies.set('onboardingType', params.onboardingType, { expires: 1, sameSite: 'None', secure: true });
            if (params && params.inviteRoute) Cookies.set('inviteRoute', params.inviteRoute, { expires: 1 });
            if (params && params.inviteCode) Cookies.set('inviteCode', params.inviteCode, { expires: 1 });
            dispatch(saveParams(params));
        }
    }, [dispatch, location.search]);

    const setupZendeskCallback = React.useCallback(() => {
        if (window.zE) {
            // Zendesk language selection
            window.zE('webWidget', 'setLocale', locale);

            // Zendesk set user email
            if (user?.email) {
                window.zE('webWidget', 'identify', {
                    email: user.email,
                });
                // Zendesk prefill
                window.zE('webWidget', 'prefill', {
                    email: {
                        value: user.email,
                    },
                });
            }
        }
    }, [locale, user]);

    const setupPendoCallback = React.useCallback(() => {
        if (window.pendo) {
            const { team: account, ...visitor } = user;

            const accountSettings = account?.settings_config;
            const hrSettings = accountSettings?.hr_application_column_settings;
            const pmSettings = accountSettings?.pm_application_column_settings;

            const pendoMetadata = {
                visitor: {
                    id: visitor?.id,
                    name: visitor?.name,
                    email: visitor?.email,
                    is_hr_user: visitor?.is_hr_user,
                    is_adjudicator: visitor?.is_adjudicator,
                    is_staff: visitor?.is_staff,
                    permission_level: visitor?.permission_level,
                    permission_level_display: visitor?.permission_level_display,
                    onboarding_id: Cookies.get('onboardingId'),
                    onboarding_type: Cookies.get('onboardingType'),
                    locale,
                },
                account: {
                    // team
                    id: account?.id,
                    email: account?.email,
                    name: account?.name,
                    internal_name: account?.internal_name,
                    created: account?.created,
                    accounting_email: account?.accounting_email,
                    payment_verified: account?.payment_verified,
                    province_state: account?.province_state,
                    country: account?.country,
                    allow_team_select: account?.allow_team_select,
                    smb: account?.smb,
                    is_lime: account?.is_lime,
                    is_new: account?.is_new,
                    applicant_invites_blocked: account?.applicant_invites_blocked,
                    // settings_config
                    state_or_territory: accountSettings?.state_or_territory,
                    applicant_must_prepay: accountSettings?.applicant_must_prepay,
                    get_org_country: accountSettings?.get_org_country,
                    whitelabel_report: accountSettings?.whitelabel_report,
                    internal_name_settings: accountSettings?.internal_name,
                    webhook_url: !!accountSettings?.webhook_url,
                    custom_tracks: accountSettings?.custom_tracks,
                    workable_fast_complete: accountSettings?.workable_fast_complete,
                    enable_rcmp_reflow: accountSettings?.enable_rcmp_reflow,
                    whitelabel_emails: accountSettings?.whitelabel_emails,
                    follow_up_international_criminal_record_check:
                        accountSettings?.whitelabel_emails?.follow_up_international_criminal_record_check,
                    international_follow_up_flow_cost_threshold:
                        accountSettings?.whitelabel_emails?.international_follow_up_flow_cost_threshold,
                    custom_questions: accountSettings?.whitelabel_emails?.custom_questions?.length !== 0,
                    notification_frequency: accountSettings?.notification_frequency,
                    unresponsive_can_send_expiry_notification_dashboard:
                        accountSettings?.unresponsive_can_send_expiry_notification_dashboard,
                    checks_that_do_not_trigger_partial_adjudication:
                        accountSettings?.checks_that_do_not_trigger_partial_adjudication,
                    // hr_application_column_settings
                    hr_updated: hrSettings?.updated,
                    hr_email: hrSettings?.email,
                    hr_first_name: hrSettings?.first_name,
                    hr_last_name: hrSettings?.last_name,
                    hr_package: hrSettings?.package,
                    hr_team: hrSettings?.team,
                    hr_owner: hrSettings?.owner,
                    hr_score: hrSettings?.score,
                    hr_actions: hrSettings?.actions,
                    hr_info: hrSettings?.info,
                    hr_adjudication_status: hrSettings?.adjudication_status,
                    hr_application_status: hrSettings?.application_status,
                    // pm_application_column_settings
                    pm_updated: pmSettings?.updated,
                    pm_email: pmSettings?.email,
                    pm_first_name: pmSettings?.first_name,
                    pm_last_name: pmSettings?.last_name,
                    pm_unit: pmSettings?.unit,
                    pm_addresses: pmSettings?.addresses,
                    pm_co_applicants: pmSettings?.co_applicants,
                    pm_co_signers: pmSettings?.co_signers,
                    pm_team: pmSettings?.team,
                    pm_owner: pmSettings?.owner,
                    pm_score: pmSettings?.score,
                    pm_actions: pmSettings?.actions,
                    pm_info: pmSettings?.info,
                    pm_adjudication_status: pmSettings?.adjudication_status,
                    pm_application_status: pmSettings?.application_status,
                    // billing_plan
                    billing_id: account?.billing_plan?.id,
                    billing_team: account?.billing_plan?.team,
                    billing_plan_type: account?.billing_plan?.plan_type,
                    billing_monthly_price: account?.billing_plan?.monthly_price,
                    billing_monthly_minimum: account?.billing_plan?.monthly_minimum,
                    billing_currency_type: account?.billing_plan?.currency_type,
                    // superteam
                    superteam_id: account?.superteam?.id,
                    superteam_name: account?.superteam?.name,
                    superteam_hubspot_id: account?.superteam?.hubspot_id,
                    superteam_parsed_soquij_report: account?.superteam?.parsed_soquij_report,
                    // account_manager
                    account_manager_first_name: account?.account_manager?.first_name,
                    account_manager_last_name: account?.account_manager?.last_name,
                    account_manager_email: account?.account_manager?.email,
                },
            };

            if (window.pendo.isReady()) {
                window.pendo.updateOptions(pendoMetadata);
            } else {
                window.pendo.initialize(pendoMetadata);
            }
        }
    }, [user, locale]);

    // if subdomain fetch custom skins
    React.useEffect(() => {
        const subdomain = Path.getCurrentSubdomain();
        initFavicon(subdomain);
        if (subdomain) {
            dispatch(fetchSkin(subdomain))
                .catch((error) => ErrorAlertNoTranslate({ description: error }))
                .finally(() => setIsLoading(false));
        } else setIsLoading(false); // should complete loading if no subdomain matches
    }, [dispatch]);

    // component did mount
    React.useEffect(() => {
        // once we've mounted, don't run
        if (isMounted.current) return;
        // If the onboarding ID and password token ID are in the URL, set them to cookies
        saveParamsCallback();
        // Load Google Places
        GooglePlaces.initGooglePlaces();
        // Init language preference
        initLocaleCallback();
        // listens for matchMedia event triggers at the given breakpoint
        const matchMedia = window.matchMedia('(max-width: 1024px)');
        matchMedia.onchange = () => updateComponent();
        // first mount
        isMounted.current = true;
    }, [dispatch, saveParamsCallback, initLocaleCallback]);

    // zendesk
    React.useEffect(() => {
        // Load Zendesk, if fails, give script a chance to load (on slow devices)
        if (window.zE) setupZendeskCallback();
        else setTimeout(setupZendeskCallback, 3000);
    }, [dispatch, setupZendeskCallback]);

    // pendo
    React.useEffect(() => {
        // Load Pendo, if fails, give script a chance to load (on slow devices)
        if (window.pendo) {
            setupPendoCallback();
        } else {
            setTimeout(setupPendoCallback, 3000);
        }
    }, [setupPendoCallback]);

    // google analytics tracking page view data
    useTrackingPageViews();
    return (
        <>
            {isLoading || (
                <GoogleRecaptchaWrapper>
                    <GlobalStyles />
                    <StyledThemeProvider theme={activeTheme}>
                        <GlobalProvider locale={locale} language={language}>
                            <Auth0Provider
                                domain={config.domain}
                                client_id={config.clientId}
                                redirect_uri={`${window.location.origin}/login`}
                                audience={config.audience}
                            >
                                <CertnUiWrapper>
                                    <ScrollToTop>
                                        <BaseContainer>
                                            <RouteErrorBoundary>
                                                <Switch>
                                                    {/* LOGOUT */}
                                                    <Route path="/logout" component={Logout} />
                                                    {/* --- GUEST --- */}
                                                    <Route path="/integrate/:integration?" component={Guest} />
                                                    <Route path="/apply/:posting_id?" component={Guest} />
                                                    <Route path="/hr/apply/:posting_id?" component={Guest} />
                                                    <Route path="/dispute/:applicant_id" component={Guest} />
                                                    <Route path="/mvr" component={Guest} />
                                                    {/* --- REFERENCE --- */}
                                                    <Route path="/reference" component={Reference} />
                                                    {/* --- APPLICANT --- */}
                                                    <Route path="/welcome" component={Welcome} />
                                                    <Route
                                                        path="/:type(hr|pm)/applicant/:applicantId/report"
                                                        component={ReportVerify}
                                                    />
                                                    <Route
                                                        path="/:type(hr|pm)/applicant/:applicantId/adverse-action"
                                                        component={AdverseActionVerify}
                                                    />
                                                    <Route
                                                        path="/hr/applicant/:applicantId/pre-adverse-action"
                                                        component={AdverseActionVerify}
                                                    />
                                                    <Route path="/applicant" component={Applicant} />
                                                    {/* --- MANAGER --- */}
                                                    <Routes.Authed hr path="/hr" component={Manager} />
                                                    <Routes.Authed pm path="/pm" component={Manager} />
                                                    <Routes.Authed path="/notifications" component={Manager} />
                                                    <Routes.Authed path="/settings" component={Manager} />
                                                    <Routes.Authed path="/admin" component={Manager} />
                                                    <Routes.Authed path="/partner" component={Manager} />
                                                    {/* --- DEFAULT --- */}
                                                    <Route path="/login" component={Guest} />
                                                    <Route path="/" component={Guest} />
                                                    <Redirect to="/" />
                                                </Switch>
                                            </RouteErrorBoundary>
                                        </BaseContainer>
                                    </ScrollToTop>
                                </CertnUiWrapper>
                            </Auth0Provider>
                        </GlobalProvider>
                    </StyledThemeProvider>
                </GoogleRecaptchaWrapper>
            )}
        </>
    );
};

export default withLDConsumer()(Base);
